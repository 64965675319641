import React from "react"
import { graphql, Link } from "gatsby"

import "../assets/css/stylist.scss"
import Layout from "../components/layout"
import Feature from "../components/feature"
import LooksSlider from "../components/looksSlider"
import { FixedImage } from "../services/images"
import FullCTA from "../components/fullCTA"
import { Sort } from "../services/utils"

const Stylist = ({ data }) => {
  const article = data.tst.Stylist || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  article.pageMetaData.url = article.url
  article.pageMetaData.title = article.name
  const name = article.name.split(" ")[0]
  const ctas = Sort(article.ctas, article.ctasSort || "")
  console.log(article)
  return (
    <Layout meta={article.pageMetaData} title={article.name}>
      <section className="breadcrumbs my-lg mx-auto">
        <Link to="/meet-our-stylists">Our Stylists</Link> /{" "}
        <span className="current">{name}</span>
      </section>
      <section className="side-by-side my-lg mx-auto pb-lg sm-mb-n">
        <div className="text pr-md sm-pr-n">
          <h1>{article.name}</h1>
          <div
            className="detail"
            dangerouslySetInnerHTML={{ __html: article.text }}
          ></div>
          <div className="talign-center mt-lg">
            <a
              href="https://app.thestiltrust.com/mens/join"
              rel="noreferrer"
              target="_blank"
            >
              <button>Start Your Style Quiz</button>
            </a>
          </div>
        </div>

        <div className="image pl-md sm-pl-n">
          <FixedImage
            src={article.image.image.id}
            alt={article.name}
            options={{ fit: "contain" }}
          />

          {article.topCta && (
            <div className="mb-lg">
              <FullCTA data={article.topCta} condensed={true} />
            </div>
          )}
        </div>
      </section>
      <LooksSlider data={article.looks || []} name={article.name} />
      <Feature
        condensed={true}
        data={{
          title: `${name}'s Style`,
          full: false,
          image: null,
          backgroundColor: "lavendar",
          blocks: [
            {
              title: `Pro Styling Tips`,
              blockType: "text",
              text: article.stylingTip,
              image: null,
              backgroundColor: "lavendar",
            },
            {
              title: `Styling Aesthetic`,
              blockType: "text",
              text: article.stylingAsthetic,
              image: null,
              backgroundColor: "lavendar",
            },
            {
              title: `Favorite Styling Piece`,
              blockType: "text",
              text: article.favoriteStyle,
              image: null,
              backgroundColor: "lavendar",
            },
          ],
        }}
      />
      <div className="padded-flavor">
        <Feature
          data={{
            title: "",
            full: true,
            image: article.stylingImage,
            backgroundColor: "lavendar",
            blocks: [],
          }}
        />
      </div>
      <Feature
        condensed={true}
        data={{
          title: "",
          full: false,
          image: null,
          backgroundColor: "lavendar",
          blocks: [
            {
              title: `${name}'s Area of expertise:`,
              blockType: "text",
              text: article.expertise,
              image: null,
              backgroundColor: "lavendar",
            },
            {
              title: `Types of Looks ${name} Loves Helping Clients With:`,
              blockType: "text",
              text: article.lookTypes,
              image: null,
              backgroundColor: "lavendar",
            },
            {
              title: ``,
              blockType: "text",
              text: "",
              image: null,
              backgroundColor: "lavendar",
            },
          ],
        }}
      />
      {ctas &&
        ctas.length > 0 &&
        ctas.map((cta, idx) => <FullCTA data={cta} key={`s-cta-${idx}`} />)}
    </Layout>
  )
}

export const query = graphql`
  query StylistQuery($id: ID!) {
    tst {
      Stylist(where: { id: $id }) {
        name
        text
        url
        ctas {
          ...CTA
        }
        ctasSort
        image {
          ...Image
        }
        stylingImage {
          ...Image
        }
        looks {
          ...Look
        }
        expertise
        lookTypes
        stylingTip
        stylingAsthetic
        favoriteStyle
        topCta {
          ...CTA
        }
      }
    }
  }
`

export default Stylist
