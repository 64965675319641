import React from "react"
import "../assets/css/looksSlider.scss"
import { FixedImage } from "../services/images"

import swipeArrow from "../../static/swipe-arrow.png"

export default ({ data = [], name = "" }) => {
  return (
    <section className="looks-slider">
      <h2>{name.split(" ")[0]}'s Looks</h2>
      <section className="slider">
        {data.map(look => {
          return (
            <div className="look">
              <FixedImage src={look.image.image.id} />
            </div>
          )
        })}
      </section>
      <div className="swipe-text">
        <img src={swipeArrow} width="64" />
        swipe to view all looks
      </div>
    </section>
  )
}
